import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import {calculateTextPosition, formatText, getBottomYPosition} from "../../utils/utils";

export const createJobInfos1Template = async (pdfDoc, data, backgroundImageUrl, fonts, jobIndex = 0) => {
    const jobTitle = formatText(`${data.results[jobIndex].metier.toUpperCase()} - FICHE MÉTIER`, 30);
    const page = await createPageTemplate(pdfDoc, data, backgroundImageUrl, true, jobTitle);

    const jobInfo = data.jobInfos.find(job => job.metierNom === data.results[jobIndex].metier);

    const titleYPosition = layout.pageHeight - layout.margin - 160;
    drawTextElement(page, "MISSION", fonts.titleFont, styles.titleStyle, layout.margin, titleYPosition);

    const textMission = formatText(jobInfo.mission, 60);
    const textMissionYPosition = getBottomYPosition("MISSION", fonts.titleFont, styles.titleStyle.size, titleYPosition, styles.titleStyle.size * 1.5);
    drawTextElement(page, textMission, fonts.bodyFont, styles.bodyStyle, layout.margin, textMissionYPosition - 20);

    const activityTitleYPosition = getBottomYPosition(textMission, fonts.bodyFont, styles.bodyStyle.size, textMissionYPosition, styles.bodyStyle.size * 1.5) - 80;
    drawTextElement(page, "ACTIVITÉS", fonts.titleFont, styles.titleStyle, layout.margin, activityTitleYPosition);

    const activityText = jobInfo.activities.map(activity => `• ${formatText(activity)}`).join("\n");
    drawTextElement(page, activityText, fonts.bodyFont, styles.bodyStyle, layout.margin, activityTitleYPosition - 80);

    return page;
};

// TODO : move in utils
const drawTextElement = (page, text, font, style, x, y) => {
    const { x: adjustedX, y: adjustedY } = calculateTextPosition(text, font, style.size, y, "left");
    page.drawText(text, {
        x: adjustedX,
        y: adjustedY,
        size: style.size,
        font: font,
        color: style.color || rgb(35 / 255, 40 / 255, 58 / 255),
        lineHeight: style.size * 1.5,
    });
};