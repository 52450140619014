import {PDFDocument} from "pdf-lib";
import {
  createAccueilPage,
  createCtaPage,
  createJobSynthesisTemplatesV2,
  createSommairePage,
  createTopPage,
} from "../templates/common";
import {
  createGeneralTemplate,
  createJobInfos1Template,
  createJobInfos2Template,
  createSkillDetailsTemplate,
  createSynthesisJobTemplate,
} from "../templates/metier";
import {loadFonts} from "../config/fonts";
import {getBackgroundImages, savePdf} from "./utils";
import jobs from "@/data/metiers.json";
import jobInfos from "@/data/metier_infos.json";
import {createTransitionPage} from "@/lib/pdf/templates/common/transitionTemplate";


export const generatePdf = async (
    name,
    quiz_code,
    resultsQuiz,
    answersQuiz,
    skillPoolsQuiz,
    onProgressUpdate // paramètre pour les maj progression
) => {
  const pdfDoc = await PDFDocument.create();
  const fonts = await loadFonts(pdfDoc);
  const { bgAccueil, bgSommaire, bgTop, bgGeneral, bgCta, bgPlain } = getBackgroundImages();

  const data = { name, quiz_code, results: resultsQuiz, jobs, jobInfos, skillPools: skillPoolsQuiz, answers: answersQuiz };

  onProgressUpdate(10);

  await createAccueilPage(pdfDoc, data, bgAccueil, true, fonts);
  onProgressUpdate(20);

  await createSommairePage(pdfDoc, data, bgSommaire, true, fonts);
  onProgressUpdate(30);

  await createTopPage(pdfDoc, data, bgTop, fonts);
  onProgressUpdate(40);

  // Boucle sur les templates de résultats
  for (let i = 0; i < data.results.length; i++) {
    await createTransitionPage(pdfDoc, data, bgSommaire, fonts, i);
    onProgressUpdate(40 + (i / data.results.length) * 30);
    await createGeneralTemplate(pdfDoc, data, bgGeneral, fonts, i);
    await createSynthesisJobTemplate(pdfDoc, data, bgGeneral, fonts, i);
    await createSkillDetailsTemplate(pdfDoc, data, bgPlain, fonts, i);
    await createJobInfos1Template(pdfDoc, data, bgSommaire, fonts, i);
    await createJobInfos2Template(pdfDoc, data, bgSommaire, fonts, i);
  }

  onProgressUpdate(80);

  await createJobSynthesisTemplatesV2(pdfDoc, JSON.parse(localStorage.getItem("quiz_results")), bgGeneral, fonts);
  onProgressUpdate(90);

  await createCtaPage(pdfDoc, data, bgCta, false, fonts);
  onProgressUpdate(95);

  const pdfBytes = await savePdf(pdfDoc, "rapport.pdf");
  onProgressUpdate(100);

  return pdfBytes;
};
