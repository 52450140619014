import { rgb } from 'pdf-lib';
import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import Chart from 'chart.js/auto';
import {formatText} from "../../utils/utils";

const jobCategories = {
    "ORGANISATION ET GESTION DES ÉVOLUTIONS DU SYSTÈME D’INFORMATION": [
        "Consultant en systèmes d’information",
        "Urbaniste des systèmes d’information",
        "Responsable du système d’information « métier »",
        "Gestionnaire d’applications",
        "Chargé d’affaires internes",
        "Architecte d’entreprise",
        "Responsable Green IT"
    ],
    "MANAGEMENT DE PROJETS": [
        "Directeur de projets",
        "Chef de projet maîtrise d'ouvrage",
        "Chef de projet maîtrise d’œuvre",
        "Coach agile",
        "Scrum Master",
        "Product Owner",
        "Chargé de pilotage SI (PMO)",
        "Product Manager"
    ],
    "CYCLE DE VIE DES APPLICATIONS": [
        "Responsable des systèmes applicatifs",
        "Concepteur -Développeur",
        "Testeur",
        "Intégrateur d’applications",
        "Paramétreur de progiciels"
    ],
    "MISE À DISPOSITION ET MAINTENANCE EN CONDITION OPÉRATIONNELLE DES INFRASTRUCTURES - PARTIE 1": [
        "Technicien d’exploitation",
        "Technicien poste de travail",
        "Technicien réseaux-télécoms",
        "Administrateur d’outils / de systèmes / de réseaux -télécoms",
    ],
    "MISE À DISPOSITION ET MAINTENANCE EN CONDITION OPÉRATIONNELLE DES INFRASTRUCTURES - PARTIE 2": [
        "Administrateur de bases de données",
        "Intégrateur d’exploitation",
        "Pilote d’exploitation",
        "Expert systèmes d’exploitation / réseau télécom",
        "Architecte technique"
    ],
    "SUPPORT ET ASSISTANCE": [
        "Assistant fonctionnel",
        "Technicien support utilisateurs",
        "Expert méthode et outils / qualité"
    ],
    "SÉCURITÉ": [
        "Expert en cybersécurité",
        "Auditeur SSI",
        "Responsable sécurité des Systèmes d’Information RSSI"
    ],
    "MANAGEMENT OPÉRATIONNEL - PARTIE 1": [
        "Directeur des systèmes d’information",
        "Responsable d’entité",
        "Responsable Télécoms",
        "Responsable d’exploitation",
    ],
    "MANAGEMENT OPÉRATIONNEL - PARTIE 2": [
        "Responsable d’études",
        "Chief Digital Officer",
        "Responsable marketing de la DSI"
    ],
    "DONNÉES": [
        "Data Scientist",
        "Data Analyst",
        "Chief Data Officer",
        "Data Engineer",
        "Délégué à la protection des données",
        "Data Architect"
    ],
    "RELATIONS FOURNISSEURS": [
        "Manager de contrats",
        "Acheteur IT",
        "Software Asset Manager - SAM",
        "Vendor Manager"
    ]
};

export const createJobSynthesisTemplates = async (pdfDoc, data, backgroundImageUrl, fonts) => {
    for (const category in jobCategories) {
        const categoryJobs = filterJobsByCategory(data, category);

        const maxJobsPerPage = 10;
        for (let i = 0; i < categoryJobs.length; i += maxJobsPerPage) {
            const jobsForCurrentPage = categoryJobs.slice(i, i + maxJobsPerPage);
            await createJobSynthesisTemplate(pdfDoc, jobsForCurrentPage, backgroundImageUrl, fonts, category);
        }
    }
};

const createJobSynthesisTemplate = async (pdfDoc, jobs, backgroundImageUrl, fonts, category) => {
    const page = await createPageTemplate(pdfDoc, {}, backgroundImageUrl, true, formatText(`SYNTHÈSE DES MÉTIERS - ${category.toUpperCase()}`, 30));

    const barChartImageUrl = await generateBarChartImage(jobs);

    const barChartImageBytes = await fetch(barChartImageUrl).then(res => res.arrayBuffer());
    const barChartImageEmbed = await pdfDoc.embedPng(barChartImageBytes);
    const chartDims = barChartImageEmbed.scale(0.6);

    page.drawImage(barChartImageEmbed, {
        x: layout.pageWidth / 2 - chartDims.width / 2,
        y: layout.pageHeight / 2 - chartDims.height / 2 - 80,
        width: chartDims.width,
        height: chartDims.height,
    });

    return page;
};

const filterJobsByCategory = (results, category) => {
    const jobsInCategory = jobCategories[category] || [];
    return results.filter(job => jobsInCategory.includes(job.job));
};

const generateBarChartImage = async (jobs) => {
    return new Promise((resolve) => {
        // Create a div container for the chart
        const container = document.createElement('div');
        container.style.width = '800px';
        container.style.height = '600px';
        container.style.padding = '20px';
        document.body.appendChild(container);

        // Create a canvas element inside the container
        const canvas = document.createElement('canvas');
        container.appendChild(canvas);

        const ctx = canvas.getContext('2d');

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: jobs.map(job => job.job.split(' ')),
                datasets: [{
                    label: 'Niveau de compatibilité',
                    data: jobs.map(job => job.percentage),
                    backgroundColor: 'rgba(255, 142, 49, 1)',
                    borderColor: 'rgba(255, 142, 49, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: false,
                            text: 'Métiers'
                        },
                        ticks: {
                            autoSkip: false,
                            maxRotation: 90,
                            font: {
                                size: 18
                            }
                        }
                    },
                    y: {
                        title: {
                            display: false,
                            text: 'Niveau de compatibilité'
                        },
                        min: 0,
                        max: 100,
                        ticks: {
                            stepSize: 20,
                            font: {
                                size: 18
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        position: 'top',
                        labels: {
                            font: {
                                size: 24
                            }
                        }
                    }
                }
            }
        });

        // Wait for the chart to be rendered before converting it to a base64 image
        setTimeout(() => {
            const imageUrl = canvas.toDataURL('image/png');
            document.body.removeChild(container); // Remove the entire container
            resolve(imageUrl);
        }, 1000);
    });
};
