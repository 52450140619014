import { rgb } from 'pdf-lib';
import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import { calculateTextPosition, formatText, getBottomYPosition } from "../../utils/utils";
import { analyzeTopSkillGaps } from "../../../algorithms/skillGapAnalyzer";

export const createSynthesisJobTemplate = async (pdfDoc, data, backgroundImageUrl, fonts, jobIndex = 0) => {
    const jobTitle = data.results[jobIndex].metier.toUpperCase();
    const page = await createPageTemplate(pdfDoc, data, backgroundImageUrl, true, formatText(`${jobTitle} - SYNTHÈSE`, 30));

    const job = data.jobs.find(job => job.title === data.results[jobIndex].metier);
    const jobSignatures = job.signatures.find(signature => signature.quiz_code === data.quiz_code);
    const topSkills = analyzeTopSkillGaps(data.answers, jobSignatures);

    const synthesisText = generateSynthesisText(data.results[jobIndex].score);
    const improvementText = formatText(generateImprovementText(topSkills));

    const synthesisTitleYPosition = layout.pageHeight - layout.margin - 160;
    drawTextElement(page, "SYNTHÈSE", fonts.titleFont, styles.titleStyle, layout.margin, synthesisTitleYPosition);

    const synthesisTextYPosition = getBottomYPosition("SYNTHÈSE", fonts.titleFont, styles.titleStyle.size, synthesisTitleYPosition, styles.titleStyle.size * 1.5);
    drawTextElement(page, synthesisText, fonts.bodyFont, styles.bodyStyle, layout.margin, synthesisTextYPosition - 10);

    const improvementTitleYPosition = getBottomYPosition(synthesisText, fonts.bodyFont, styles.bodyStyle.size, synthesisTextYPosition - 60, styles.bodyStyle.size * 1.5) - 80;
    drawTextElement(page, "AXES D'AMÉLIORATION", fonts.titleFont, styles.titleStyle, layout.margin, improvementTitleYPosition);

    drawTextElement(page, improvementText, fonts.bodyFont, styles.bodyStyle, layout.margin, improvementTitleYPosition - 60);

    return page;
};

const generateSynthesisText = (score) => {
    const baseText = "D'après vos résultats, votre profil\nmatche avec ce métier.\n\nCependant, le métier est sous-dimensionné,\nvous risquez de vous ennuyer.";
    return score < 70 ? baseText.replace("matche", "ne matche pas") : baseText;
};

const generateImprovementText = (topSkills) => {
    const placeholders = topSkills.map(skill => skill.skill).filter(Boolean);

    if (placeholders.length === 0) {
        return "Vous semblez avoir le niveau pour chacune des compétences essentielles de ce métier, gardez le cap, perfectionnez-les et n'hésitez pas à faire de la veille !";
    }

    const improvementMessages = [];
    if (placeholders[0]) {
        improvementMessages.push(`Vous semblez avoir une certaine ${placeholders[0]}, mais il est essentiel de la faire progresser.`);
    }
    if (placeholders[1]) {
        improvementMessages.push(`Vous devriez développer davantage votre ${placeholders[1]} pour maximiser votre réussite.`);
    }
    if (placeholders[2]) {
        improvementMessages.push(`Votre capacité en ${placeholders[2]} semble en deçà des attentes du métier.`);
    }

    return improvementMessages.join("\n\n");
};

const drawTextElement = (page, text, font, style, x, y) => {
    const { x: adjustedX, y: adjustedY } = calculateTextPosition(text, font, style.size, y, "left");
    page.drawText(text, {
        x: adjustedX,
        y: adjustedY,
        size: style.size,
        font: font,
        color: style.color || rgb(35 / 255, 40 / 255, 58 / 255),
        lineHeight: style.size * 1.5,
    });
};
