/**
 * Concatenates the characters of the input string, converts each character to an integer,
 * and returns the sum of these integers.
 *
 * @param {string} str - The input string containing numeric characters to be summed.
 * @return {number} The sum of the numeric characters in the input string.
 */
function concatAndSum(str) {
    return str.split('').reduce((sum, char) => sum + parseInt(char), 0);
}

/**
 * Calculates the power of a signature based on a given factor.
 *
 * @param {string} signature - The signature to be processed.
 * @param {number} factor - The factor to be used in the calculation.
 * @return {number} The calculated power of the signature.
 */
function calculatePower(signature, factor) {
    const sum = concatAndSum(signature);
    return (sum / factor >= 2.5)
        ? factor * Math.pow(3, sum / factor)
        : factor * Math.pow(3, 5 - sum / factor);
}

/**
 * Calculates the power of a signature based on a factor of 33.
 * @param {string} signature - The signature to be processed.
 * @returns {number} The calculated power of the signature.
 */
export function powerCigSimp(signature) {
    return calculatePower(signature, 33);
}
