"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { Result_img } from "@/public";
import ChartHeroJob from "./chart-hero-job";
import SkillsSection from "@/components/common/otherComponents/skills-section-result";

const HeroResult = () => {
  const [bestJob, setBestJob] = useState<string | null>(null);

  useEffect(() => {
    const storedResults = localStorage.getItem("quiz_results");
    if (storedResults) {
      const parsedResults = JSON.parse(storedResults);
      const bestMatch = parsedResults.reduce((prev: any, current: any) =>
        prev.percentage > current.percentage ? prev : current
      );
      setBestJob(bestMatch.job);
    }
  }, []);

  return (
    <section
      className="w-full min-h-screen relative pt-16 md:pt-0"
      aria-label="Hero Section"
    >
      <div className="absolute top-0 -z-20 h-full w-full bg-[#F2EDE9] -z-40"></div>
      <div className="absolute top-0 -z-10 h-full w-full bg-[radial-gradient(#cccccc_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_70%,transparent_100%)]"></div>
      <div className="w-full h-full flex md:flex-row flex-col">
        {/* <Image
          alt="Hero Image"
          className="px-6 py-6 object-contain object-center md:w-1/2 w-full md:h-screen h-1/2 order-last"
          src={Result_img}
        /> */}

        <div className="flex flex-col justify-center space-y-4 md:mt-2 mt-8 md:ml-8 lg:ml-16 ml-0 md:px-0 px-6 md:w-1/2 w-full md:h-screen h-1/2">
          <section className="flex flex-col gap-6">
            <h3 className="font-extrabold text-[#6D4C41]">Bravo !</h3>
            <h3 className="font-extrabold">
              En analysant tes compétences, nous avons identifié le métier qui
              te correspond le mieux :{" "}
            </h3>
            {bestJob && (
              <h1 className="text-3xl font-extrabold tracking-tighter sm:text-5xl xl:text-6xl/none mb-4">
                {bestJob.toUpperCase()}
              </h1>
            )}
          </section>

          <SkillsSection />
        </div>
        <div className="px-6 py-6 md:py-0 md:mr-6 md:w-1/2 w-full md:h-screen h-1/2 flex justify-center items-center">
          <ChartHeroJob />
        </div>
      </div>
    </section>
  );
};

export default HeroResult;
