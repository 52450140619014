import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import {addCenteredStyle, calculateTextPosition, formatText, getBottomYPosition} from "../../utils/utils";

export const createTransitionPage = async (pdfDoc, data, backgroundImageUrl, fonts, jobIndex) => {

    const jobTitle = data.results[jobIndex].metier;
    const jobInfo = data.jobInfos.find(job => job.metierNom === jobTitle);
    const jobDescription = jobInfo?.mission || "Description du métier indisponible.";

    const page = await createPageTemplate(pdfDoc, data, backgroundImageUrl, false);

    const formattedJobTitle = addCenteredStyle(formatText(jobTitle.toUpperCase(), 25));
    const { x: titleX, y: titleY } = calculateTextPosition(
        formattedJobTitle,
        fonts.titleFont,
        styles.titleStyle.size,
        layout.pageHeight / 2 + 250,
        "center"
    );
    page.drawText(formattedJobTitle, {
        x: titleX - 30,
        y: titleY,
        size: styles.titleStyle.size + 10,
        font: fonts.titleFont,
        color: styles.titleStyle.color || rgb(35 / 255, 40 / 255, 58 / 255),
        lineHeight: styles.titleStyle.size * 1.5,
    });

    // const bottomYTitle = getBottomYPosition(titleY, styles.titleStyle.size + 10, titleY, 1.5);

    const formattedJobDescription = formatText(jobDescription, 70);
    const { x: descX, y: descY } = calculateTextPosition(
        formattedJobDescription,
        fonts.bodyFont,
        styles.bodyStyle.size,
        titleY - 200,
        "center"
    );
    page.drawText(formattedJobDescription, {
        x: descX,
        y: descY,
        size: styles.bodyStyle.size,
        font: fonts.bodyFont,
        color: styles.bodyStyle.color || rgb(35 / 255, 40 / 255, 58 / 255),
        lineHeight: styles.bodyStyle.size * 1.5,
    });

    return page;
};
