import { rgb } from 'pdf-lib';

export const styles = {
    topTitleStyle: { color: rgb(35 / 255, 40 / 255, 58 / 255), size: 32 },
    titleStyle: { color: rgb(35 / 255, 40 / 255, 58 / 255), size: 36 },

    bodyHomeStyle: { color: rgb(35 / 255, 40 / 255, 58 / 255), size: 46 },
    bodyStyle: { color: rgb(35 / 255, 40 / 255, 58 / 255), size: 24 },
    subtitleStyle: { color: rgb(35 / 255, 40 / 255, 58 / 255), size: 24 },

};