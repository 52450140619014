"use client";
import React, { useState } from "react";
import { arrayFAQ } from "@/constants/arrayFAQ";

type Props = {};

const FaqPage = (props: Props) => {
  const [expanded, setExpanded] = useState(null);

  const toggleFAQ = (index: any) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <section className="py-8 md:py-24 px-0 md:px-16 lg:px-32" id="faq">
      <ul className="basis-1/2">
        {arrayFAQ.map((content, index) => (
          <li key={index}>
            <button
              className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
              aria-expanded={expanded === index}
              onClick={() => toggleFAQ(index)}
              aria-label="Toggle FAQ: How secure is my insurance information?"
            >
              <span className="flex-1 text-lg text-base-content">
                {content.title}
              </span>
              <svg
                className="flex-shrink-0 w-4 h-4 ml-auto fill-current"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="7"
                  width="16"
                  height="2"
                  rx="1"
                  className={`transform origin-center transition duration-200 ease-out ${
                    expanded !== index ? "rotate-90" : ""
                  }`}
                ></rect>
                <rect
                  y="7"
                  width="16"
                  height="2"
                  rx="1"
                  className="transform origin-center transition duration-200 ease-out"
                ></rect>
              </svg>
            </button>
            <div
              className={`transition-all duration-300 ease-in-out overflow-hidden ${
                expanded === index ? "max-h-screen" : "max-h-0"
              }`}
              style={{ transition: "max-height 0.3s ease-in-out 0s" }}
            >
              <div className="pb-5 leading-relaxed">
                <p className="space-y-2 leading-relaxed">
                  {content.description}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default FaqPage;
