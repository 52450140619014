import { layout } from "../config/layout";
import { styles } from "../config/styles";
import { loadFonts } from "../config/fonts";
import { calculateTextPosition, loadImage, drawImage } from "../utils/utils";
import { rgb } from "pdf-lib";

const imageCache = new Map();

export const createPageTemplate = async (
    pdfDoc,
    data,
    backgroundImageUrl,
    logo = true,
    title = null
) => {
  const page = pdfDoc.addPage([layout.pageWidth, layout.pageHeight]);
  const fonts = await loadFonts(pdfDoc);

  // Set background image if available
  if (backgroundImageUrl) {
    await drawBackgroundImage(pdfDoc, page, backgroundImageUrl);
  }

  // Set logo image if needed
  if (logo) {
    await drawLogo(pdfDoc, page);
  }

  // Add title if provided
  if (title) {
    drawTitle(page, title, fonts);
  }

  return page;
};

// Utility function to draw the background image
const drawBackgroundImage = async (pdfDoc, page, imageUrl) => {
  let imageEmbed;
  if (imageCache.has(imageUrl)) {
    imageEmbed = imageCache.get(imageUrl);
  } else {
    const imageBytes = await loadImage(imageUrl);
    imageEmbed = await pdfDoc.embedPng(imageBytes);
    imageCache.set(imageUrl, imageEmbed);
  }
  drawImage(page, imageEmbed, {
    x: 0,
    y: 0,
    width: layout.pageWidth,
    height: layout.pageHeight,
  });
};

// Utility function to draw the logo
const drawLogo = async (pdfDoc, page) => {
  const logoPath = `/pdf/images/logo.png`;

  let logoEmbed;
  if (imageCache.has(logoPath)) {
    logoEmbed = imageCache.get(logoPath);
  } else {
    const logoBytes = await loadImage(logoPath);
    logoEmbed = await pdfDoc.embedPng(logoBytes);
    imageCache.set(logoPath, logoEmbed);
  }

  const logoDims = logoEmbed.scale(0.66);
  drawImage(page, logoEmbed, {
    x: layout.margin,
    y: layout.pageHeight - layout.margin - logoDims.height,
    width: logoDims.width,
    height: logoDims.height,
  });
};

// Utility function to draw the title
const drawTitle = (page, title, fonts) => {
  const fontSize = styles.topTitleStyle.size;
  const { x, y } = calculateTextPosition(
      title,
      fonts.topTitleFont,
      fontSize,
      layout.pageHeight - layout.margin + 15
  );
  page.drawText(title, {
    x,
    y,
    size: fontSize,
    font: fonts.topTitleFont,
    color: rgb(35 / 255, 40 / 255, 58 / 255),
    lineHeight: fontSize * 1.2,
  });
};
