"use client";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";

type Props = {};

const Modal_emailSent = (props: Props) => {
  const [closeModal, setCloseModal] = useState(false);
  const searchParams = useSearchParams();

  useEffect(() => {
    const emailSent = searchParams?.get("emailSent"); // Récupère le paramètre 'emailSent' de l'URL

    if (emailSent === "true") {
      setCloseModal(true); // Affiche la modal si le paramètre est défini
    }
  }, [searchParams]);

  return (
    <div
      className={`transition duration-300 ${
        closeModal ? "relative z-50" : "hidden"
      }`}
      aria-labelledby="modal-title"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3
                    className="text-base font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Rapport envoyé avec succès !
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Ton rapport a bien été envoyé par email. Pense à vérifier
                      ta boîte de réception, ainsi que tes courriers
                      indésirables, pour le consulter.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 bg-black text-sm text-white hover:text-[#EBA41B] font-bold rounded-lg transition duration-200"
                onClick={() => setCloseModal(false)}
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal_emailSent;
