"use client";
import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import jobsData from "@/data/metiers.json";
import {
  saveResultsSelection,
  getResultsSelection,
} from "@/lib/sessionStorage";

const JobResultsSection = () => {
  const [jobResults, setJobResults] = useState<any[]>([]);
  const [selectedResults, setSelectedResults] = useState<any[]>([]);

  useEffect(() => {
    const storedResults = localStorage.getItem("quiz_results");
    if (storedResults) {
      const parsedResults = JSON.parse(storedResults);
      const filteredResults = parsedResults.filter(
        (result: any) => result.percentage > 20
      );
      const sortedResults = filteredResults.sort(
        (a: any, b: any) => b.percentage - a.percentage
      );
      setJobResults(sortedResults);
    }
    const storedSelections = getResultsSelection();
    if (storedSelections) {
      setSelectedResults(storedSelections);
    }
  }, []);

  const isSelected = (result: any) => {
    if (!result || !result.job || !result.percentage) {
      return false;
    }

    return selectedResults.some(
      (r) =>
        r?.job &&
        result?.job &&
        r.job === result.job &&
        r.percentage === result.percentage
    );
  };

  const toggleSelection = (result: any) => {
    if (!result || !result.job) {
      return;
    }

    let updatedSelections;
    if (isSelected(result)) {
      updatedSelections = selectedResults.filter(
        (r) => r.job && r.job !== result.job
      );
    } else if (selectedResults.length < 4) {
      updatedSelections = [...selectedResults, result];
    } else {
      return;
    }

    setSelectedResults(updatedSelections);
    saveResultsSelection(updatedSelections);
  };

  return (
    <section
      className="flex justify-center items-center flex-col w-full px-6 py-12 mx-auto md:px-12 lg:px-24 xl:px-32 pb-16"
      id="metier"
    >
      <div className="flex flex-col gap-4 justify-center items-center mb-4">
        <h1 className="text-xl w-full font-bold text-center text-[#6D4C41]">
          Étape 1/2 - Découvre tes résultats !
        </h1>
        <p className="max-w-[600px] text-gray-500 text-center text-base mt-4 font-emphasis">
          Commence par sélectionner 4 métiers dont tu souhaites voir les
          détails, puis scroll jusqu'en bas pour obtenir ton rapport
          personnalisé.
        </p>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-8">
        {jobResults.map((result, index) => {
          const job = jobsData.find((job) => job.title === result.job);
          const iconName = job?.["react-icon"];
          const IconComponent =
            iconName && FaIcons[iconName as keyof typeof FaIcons]
              ? FaIcons[iconName as keyof typeof FaIcons]
              : FaIcons.FaUser;

          return (
            <div
              key={index}
              className="w-44 my-2 mx-0 md:mx-6 p-4 flex justify-center items-start cursor-pointer"
              onClick={() => toggleSelection(result)}
            >
              <div
                className={`flex flex-col gap-1 items-center transition-all duration-200 ${
                  isSelected(result) ? "text-[#29B767]" : "text-[#2E3135]"
                }`}
              >
                <IconComponent
                  className="w-6 h-6 md:w-10 md:h-10 lg:w-14 lg:h-14"
                  size={24}
                />
                <p className="font-bold text-center">
                  #{index + 1} {result.job}
                </p>
                <span>{result.percentage.toFixed(2)}%</span>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default JobResultsSection;
