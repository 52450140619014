import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { PDFDocument, rgb } from 'pdf-lib';
import Chart from 'chart.js/auto';
import {formatText} from "../../utils/utils";

export const createSkillDetailsTemplate = async (pdfDoc, data, backgroundImageUrl, fonts, jobIndex = 0) => {
    const jobTitle = data.results[jobIndex].metier;
    const job = data.jobs.find(job => job.title === data.results[jobIndex].metier);
    const jobSignature = job.signatures.find(signature => signature.quiz_code === data.quiz_code).content;

    const skillsGroupedByType = groupSkillsByType(data.answers, jobSignature);

    for (const [skillPool, skillsData] of Object.entries(skillsGroupedByType)) {
        const skillChunks = splitSkillsData(skillPool, skillsData);

        for (const chunk of skillChunks) {
            const pageTitle = formatText(`${jobTitle.toUpperCase()} ${skillPool.toUpperCase()} - DÉTAILS`, 30);
            const page = await createPageTemplate(pdfDoc, data, backgroundImageUrl, true, pageTitle);

            const mixedChartImageUrl = await renderMixedChart(chunk);
            const mixedChartImageBytes = await fetch(mixedChartImageUrl).then(res => res.arrayBuffer());
            const mixedChartImageEmbed = await pdfDoc.embedPng(mixedChartImageBytes);
            const chartDims = mixedChartImageEmbed.scale(0.6);

            page.drawImage(mixedChartImageEmbed, {
                x: layout.pageWidth / 2 - chartDims.width / 2,
                y: layout.pageHeight - layout.margin - chartDims.height - 150,
                width: chartDims.width,
                height: chartDims.height
            });
        }
    }
};

const splitSkillsData = (skillPool, skillsData) => {
    if (skillPool === "Compétences Générales") {
        return [skillsData.slice(0, 8), skillsData.slice(8)];
    } else if (skillPool === "Compétences SI") {
        return [skillsData.slice(0, 6), skillsData.slice(6)];
    } else {
        return [skillsData];
    }
};

const groupSkillsByType = (userAnswers, jobSignature) => {
    return userAnswers.reduce((acc, answer) => {
        const { skill_pool, skill, signature_index, value } = answer;
        const requiredLevel = parseInt(jobSignature[signature_index], 10);
        const userLevel = parseInt(value, 10);
        if (!acc[skill_pool]) acc[skill_pool] = [];
        acc[skill_pool].push({ skill, requiredLevel, userLevel });
        return acc;
    }, {});
};

const renderMixedChart = async (skillsData) => {
    return new Promise((resolve) => {
        const container = document.createElement('div');
        container.style.width = '800px';
        container.style.height = '600px';
        container.style.margin = '0 auto';
        document.body.appendChild(container);

        const canvas = document.createElement('canvas');
        container.appendChild(canvas);
        const ctx = canvas.getContext('2d');

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: skillsData.map(skill => skill.skill),
                datasets: [
                    {
                        type: 'bar',
                        label: 'Acquis',
                        data: skillsData.map(skill => skill.userLevel),
                        backgroundColor: 'rgba(255, 142, 49, 0.7)',
                        borderColor: 'rgba(255, 142, 49, 1)',
                        borderWidth: 1,
                        yAxisID: 'y'
                    },
                    {
                        type: 'line',
                        label: 'Requis',
                        data: skillsData.map(skill => skill.requiredLevel),
                        borderColor: 'rgba(209, 53, 104, 1)',
                        backgroundColor: 'rgba(209, 53, 104, 0.2)',
                        borderWidth: 2,
                        pointRadius: 5,
                        pointBackgroundColor: 'rgba(209, 53, 104, 1)',
                        fill: false,
                        yAxisID: 'y'
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        min: 1,
                        max: 5,
                        ticks: {
                            stepSize: 1
                        },
                        title: {
                            display: true,
                            text: 'Niveau de compétences'
                        }
                    },
                    x: {
                        title: {
                            display: true,
                            text: 'Compétences'
                        }
                    }
                },
                plugins: {
                    legend: {
                        position: 'top',
                        labels: { font: { size: 14 } }
                    }
                }
            }
        });

        setTimeout(() => {
            const imageUrl = canvas.toDataURL('image/png');
            document.body.removeChild(container);
            resolve(imageUrl);
        }, 1000);
    });
};
