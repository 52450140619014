import fontkit from "@pdf-lib/fontkit";

const fontCache = new Map(); // Cache pour les polices chargées

const loadFont = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Erreur lors du chargement de la police : ${url}`);
  }
  return await response.arrayBuffer();
};

export const loadFonts = async (pdfDoc) => {
  pdfDoc.registerFontkit(fontkit);

  const fontsToLoad = {
    topTitleFont: `/pdf/fonts/LEMONMILK-Bold.otf`,
    titleFont: `/pdf/fonts/LEMONMILK-Regular.otf`,
    bodyFont: `/pdf/fonts/Capriola-Regular.ttf`,
  };

  const loadedFonts = {};

  for (const [fontKey, fontUrl] of Object.entries(fontsToLoad)) {
    if (fontCache.has(fontUrl)) {
      loadedFonts[fontKey] = fontCache.get(fontUrl);
    } else {
      const fontBytes = await loadFont(fontUrl);
      const embeddedFont = await pdfDoc.embedFont(fontBytes);
      fontCache.set(fontUrl, embeddedFont);
      loadedFonts[fontKey] = embeddedFont;
    }
  }

  return loadedFonts;
};
