/**
 * Analyzes the skill gaps between the user's skills and the job requirements.
 * @param {Array} userAnswers - The user's skill levels, with each item containing a skill, its level, and index.
 * @param {Object} jobSignatures - The job's skill requirements, containing a signature content string.
 * @returns {Array} - An array of top skills with the largest gaps, up to a maximum of 3.
 */
export const analyzeTopSkillGaps = (userAnswers, jobSignatures) => {
    // Map userAnswers and jobSignatures to calculate skill gaps
    const skillsData = userAnswers.map((answer, index) => {
        const userLevel = parseInt(answer.value, 10);
        const requiredLevel = parseInt(jobSignatures.content[index], 10);
        const difference = requiredLevel - userLevel;
        return {
            skill: answer.skill,
            skillPool: answer.skill_pool,
            userLevel,
            requiredLevel,
            difference
        };
    });

    // Filter skills with a required level of at least 3
    const filteredSkills = skillsData.filter(skill => skill.requiredLevel >= 3);

    // Sort skills by difference in descending order
    const sortedSkills = filteredSkills.sort((a, b) => b.difference - a.difference);

    // Select skills where the required level is 5 and the gap is at least 2
    let topSkills = sortedSkills.filter(skill => skill.requiredLevel === 5 && skill.difference >= 2);

    // If fewer than 3 skills found, consider level 4 skills with a gap of at least 2
    if (topSkills.length < 3) {
        topSkills = topSkills.concat(sortedSkills.filter(skill => skill.requiredLevel === 4 && skill.difference >= 2));
    }

    // If still fewer than 3 skills, consider level 3 skills where the user level is 1 or less
    if (topSkills.length < 3) {
        topSkills = topSkills.concat(sortedSkills.filter(skill => skill.requiredLevel === 3 && skill.userLevel <= 1));
    }

    // Limit to a maximum of 3 top skills
    return topSkills.slice(0, 3);
};