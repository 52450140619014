import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import { calculateTextPosition } from "../../utils/utils";

export const createAccueilPage = async (pdfDoc, data, backgroundImageUrl, logoImageUrl, fonts) => {
    const page = await createPageTemplate(pdfDoc, data, backgroundImageUrl, logoImageUrl);

    const personalizedText = `${data.name}, d'après notre\nanalyse, votre profil\ncorrespond au métier de\n${data.results[0].metier}`;

    const { x, y } = calculateTextPosition(
        personalizedText,
        fonts.bodyFont,
        styles.bodyHomeStyle.size,
        layout.pageHeight - layout.margin - 340,
        "left"
    );

    page.drawText(personalizedText, {
        x: x,
        y: y,
        size: styles.bodyHomeStyle.size,
        font: fonts.bodyFont,
        color: styles.bodyHomeStyle.color,
        lineHeight: styles.bodyHomeStyle.size * 1.5,
    });

    return page;
}