const arrayFAQ = [
  {
    title:
      "J’aimerais bien devenir Product Owner, ça a l’air sympa. Mais en vérité je ne sais pas en quoi consiste le métier, ni ce qu’il me faut apprendre.",
    description:
      "Sur IOVIA, tu trouveras une fiche pour chaque métier pour te guider : missions, activités et parcours professionnel.",
  },
  {
    title:
      "Est-ce que le métier de Chef de Projet est un métier d’avenir ? Avec l’agilité j’ai entendu qu’il va disparaître…",
    description:
      "Tout évolue et se transforme ! Tu auras dans l’application IOVIA des informations de tendance sur les métiers, comme par exemple les nouvelles compétences qui seront demandées dans le futur.",
  },
  {
    title:
      "Je suis un peu perdu car les annonces parlent de métiers à chaque fois différents. J’aimerais juste savoir quels sont les métiers possibles pour moi maintenant ?",
    description:
      "Quand tu auras répondu à un questionnaire, les 50 métiers seront évalués, en précisant s’ils sont adaptés à ton profil maintenant. Tu pourras voir tes points forts et tes axes d’amélioration pour une adéquation parfaite.",
  },
];

export { arrayFAQ };
