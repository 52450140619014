import Chart from "chart.js/auto";

import { layout } from "../config/layout";

// Utility function to analyze differences between job skill requirements and user skills
export const analyzeSkillsDifferences = (jobSkillPools, userSkillPools) => {
    const skillAnalysis = jobSkillPools.map((jobSkill, index) => {
        const userSkill = userSkillPools[index];
        const difference = userSkill.average - jobSkill.average;
        return {
            name: jobSkill.name,
            difference
        };
    });

    // Separate the skills into superior, inferior, and equal categories
    const superiorSkills = skillAnalysis.filter(skill => skill.difference > 0).map(skill => skill.name);
    const inferiorSkills = skillAnalysis.filter(skill => skill.difference < 0).map(skill => skill.name);
    const equalSkills = skillAnalysis.filter(skill => skill.difference === 0).map(skill => skill.name);

    return {
        superiorSkills,
        inferiorSkills,
        equalSkills
    };
};

export const addCenteredStyle = (text) => {
  const lines = text.split("\n");
  const maxLength = Math.max(...lines.map((line) => line.length));

  const centeredLines = lines.map((line) => {
    const padding = (maxLength - line.length) / 2;
    const leftPadding = " ".repeat(Math.floor(padding));
    const rightPadding = " ".repeat(Math.ceil(padding));
    return leftPadding + line + rightPadding;
  });

  return centeredLines.join("\n");
};

export const calculateTextPosition = (
  text,
  font,
  fontSize,
  startY,
  align = "right",
  pageWidth = layout.pageWidth,
  margin = layout.margin
) => {
  // Diviser le texte en lignes en fonction des sauts de ligne
  const lines = text.split(/\n+/);

  // Calculer la largeur maximale des lignes
  const maxWidth = Math.max(
    ...lines.map((line) => font?.widthOfTextAtSize(line, fontSize))
  );

  // Calculer la position X basée sur l'alignement
  let x;
  if (align === "right") {
    x = pageWidth - margin - maxWidth;
  } else if (align === "left") {
    x = margin;
  } else if (align === "center") {
    x = (pageWidth - maxWidth) / 2;
  } else {
    throw new Error(`Invalid alignment: ${align}`);
  }

  // Calculer la hauteur totale en fonction du nombre de lignes
  const lineHeight = fontSize * 1.2; // Multiplication par 1.2 pour l'espacement entre les lignes
  // const totalHeight = lineHeight * lines.length * 1.5;

  // Calculer la position Y basée sur la hauteur totale du texte
  const y = startY - lineHeight;

  return { x, y };
};

export const formatText = (text, maxLineLength = 50) => {
  const words = text.split(" ");
  let lines = [];
  let currentLine = "";

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (currentLine.length + word.length <= maxLineLength) {
      currentLine += word + " ";
    } else {
      lines.push(currentLine.trim());
      currentLine = word + " ";
    }
  }

  lines.push(currentLine.trim());
  return lines.join("\n");
};

export const getBottomYPosition = (
  text,
  font,
  fontSize,
  startY,
  lineHeight
) => {
  // Diviser le texte en lignes en fonction des sauts de ligne
  const lines = text.split("\n");

  // Calculer la hauteur totale du bloc de texte en multipliant le nombre de lignes par la hauteur de la ligne
  const totalTextHeight = lines.length * lineHeight;

  // Calculer la position Y en bas du texte (position de départ - hauteur totale du texte)
  return startY - totalTextHeight;
};

/** PDF Utils **/
export const getBackgroundImages = () => ({
  bgAccueil: `/pdf/images/bg_accueil.png`,
  bgSommaire: `/pdf/images/bg_sommaire.png`,
  bgTop: `/pdf/images/bg_top.png`,
  bgGeneral: `/pdf/images/bg_metier_general.png`,
  bgCta: `/pdf/images/cta.png`,
  bgPlain: `/pdf/images/bg_plain.png`
});

export const savePdf = async (pdfDoc, filename) => {
    return await pdfDoc.save();
    /*
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    */
};

/** Image Utils **/
export const loadImage = async (imageUrl) => {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error(`Failed to load image: ${response.statusText}`);
        }
        return await response.arrayBuffer();
    } catch (error) {
        throw error;
    }
};

export const drawImage = (page, imageEmbed, options) => {
    const { x, y, width, height } = options;
    page.drawImage(imageEmbed, {
        x,
        y,
        width,
        height
    });
};

/** Chart Utils **/
// Render a chart based on the specified type and data
export const renderChart = async (type, data, scale = 1, chartOptions = {}) => {
    return new Promise((resolve) => {
        // Create a container div to enforce chart size
        const container = document.createElement('div');
        container.style.width = `${800 * scale}px`;
        container.style.height = `${600 * scale}px`;
        container.style.margin = '0 auto';
        container.style.padding = '10px';
        document.body.appendChild(container);

        const canvas = document.createElement('canvas');
        container.appendChild(canvas);
        const ctx = canvas.getContext('2d');

        new Chart(ctx, {
            type: type,
            data: type === 'radar' ? getRadarData(data) : type === 'bar' ? getBarData(data) : getScatterData(data),
            options: { ...getDefaultOptions(type, data), ...chartOptions }
        });

        setTimeout(() => {
            const imageUrl = canvas.toDataURL('image/png');
            document.body.removeChild(container);
            resolve(imageUrl);
        }, 1000);
    });
};

// Helper function to get data for radar charts
const getRadarData = ({ jobSkillPools, userSkillPools }) => ({
    labels: jobSkillPools.map(pool => pool.name.split(' ')),
    datasets: [
        {
            label: 'Requis',
            data: jobSkillPools.map(pool => pool.average),
            backgroundColor: 'rgba(209, 53, 104, 0.2)',
            borderColor: 'rgba(209, 53, 104, 1)',
            borderWidth: 2
        },
        {
            label: 'Acquis',
            data: userSkillPools.map(pool => pool.average),
            backgroundColor: 'rgba(255, 142, 49, 0.2)',
            borderColor: 'rgba(255, 142, 49, 1)',
            borderWidth: 2
        }
    ]
});

// Helper function to get data for bar charts (updated to use job titles correctly)
const getBarData = (data) => ({
    labels: data.results.map(result => result.metier.split(' ')),
    datasets: [
        {
            data: data.results.map(result => result.score),
            backgroundColor: [
                'rgba(209, 53, 104, 0.8)',
                'rgba(255, 142, 49, 0.8)',
                'rgba(209, 53, 104, 0.8)',
                'rgba(255, 142, 49, 0.8)'
            ],
            borderColor: [
                'rgba(209, 53, 104, 1)',
                'rgba(255, 142, 49, 1)',
                'rgba(209, 53, 104, 1)',
                'rgba(255, 142, 49, 1)'
            ],
            borderWidth: 1
        }
    ]
});

// Helper function to get data for scatter charts
const getScatterData = (skillsData) => ({
    datasets: [
        {
            label: 'Acquis',
            data: skillsData.map((skill, index) => ({ x: skill.userLevel, y: index + 1 })),
            backgroundColor: 'rgba(255, 142, 49, 0.6)',
            borderColor: 'rgba(255, 142, 49, 1)',
            pointRadius: 10,
            pointHoverRadius: 12
        },
        {
            label: 'Requis',
            data: skillsData.map((skill, index) => ({ x: skill.requiredLevel, y: index + 1 })),
            backgroundColor: 'rgba(209, 53, 104, 0.6)',
            borderColor: 'rgba(209, 53, 104, 1)',
            pointRadius: 10,
            pointHoverRadius: 12
        }
    ]
});

// Get default chart options based on chart type
const getDefaultOptions = (type, data) => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: type === 'radar'
        ? { r: { ticks: { display: false }, pointLabels: { font: { size: 13 } } } }
        : type === 'scatter'
            ? {
                x: {
                    title: { display: true, text: 'Niveau de compétences' },
                    min: 1,
                    max: 5,
                    stepSize: 1,
                    grid: { display: true, color: 'rgba(0, 0, 0, 0.1)' }
                },
                y: {
                    title: { display: true, text: 'Compétences' },
                    ticks: {
                        callback: function (value, index) {
                            return data[index] ? data[index].skill.split(' ') : '';
                        },
                        stepSize: 1,
                        font: { size: 24 }
                    },
                    grid: { display: true, color: 'rgba(0, 0, 0, 0.1)' }
                }
            }
            : {
                x: {
                    title: { display: false, text: 'Métiers' },
                    beginAtZero: true,
                    grid: { display: false },
                    ticks: { font: { size: 20 } }
                },
                y: {
                    beginAtZero: true,
                    max: 100,
                    title: { display: false, text: 'Score (%)' },
                    grid: { display: true },
                    ticks: { font: { size: 20 } }
                }
            },
    plugins: {
        legend: {
            position: 'top',
            labels: { font: { size: 16 } }
        },
        tooltip: {
            enabled: true,
            backgroundColor: 'rgba(0,0,0,0.8)',
            titleFont: { size: 12 },
            bodyFont: { size: 12 }
        }
    }
});
