const SESSION_ID_KEY = "quiz_session_id";
const RESULTS_KEY = "quiz_results";
const SELECT_RESULTS_KEY = "quiz_results_selection";

export const saveSessionId = (sessionId: string) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(SESSION_ID_KEY, sessionId);
  }
};

export const getSessionId = (): string | null => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(SESSION_ID_KEY);
  }
  return null;
};

export const clearSessionId = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(SESSION_ID_KEY);
  }
};

export const saveResults = (results: any) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(RESULTS_KEY, JSON.stringify(results));
  }
};

export const getResults = (): any | null => {
  if (typeof window !== "undefined") {
    const results = localStorage.getItem(RESULTS_KEY);
    return results ? JSON.parse(results) : null;
  }
  return null;
};

export const clearResults = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(RESULTS_KEY);
  }
};

export const saveResultsSelection = (results: any) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(SELECT_RESULTS_KEY, JSON.stringify(results));
  }
};

export const getResultsSelection = (): any | null => {
  if (typeof window !== "undefined") {
    const results = localStorage.getItem(SELECT_RESULTS_KEY);
    return results ? JSON.parse(results) : null;
  }
  return null;
};

export const clearResultsSelection = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(SELECT_RESULTS_KEY);
  }
};
