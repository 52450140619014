"use client";
import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";

type Props = {};

const ChartHeroJob = (props: Props) => {
  const [jobChart, setJobChart] = useState<any[]>([]);

  useEffect(() => {
    const storedResults = localStorage.getItem("quiz_results");
    if (storedResults) {
      const parsedResults = JSON.parse(storedResults);
      const topFourJobs = parsedResults
        .sort((a: any, b: any) => b.percentage - a.percentage)
        .slice(0, 4);
      setJobChart(topFourJobs);
      console.log(topFourJobs);
    }
  }, []);

  useEffect(() => {
    if (jobChart.length > 0) {
      renderChart();
    }
  }, [jobChart]);

  const renderChart = () => {
    const ctx = document.getElementById("jobChart") as HTMLCanvasElement;
    new Chart(ctx, {
      type: "bar",
      data: getBarData(),
      options: getDefaultOptions(),
    });
  };

  const getBarData = () => ({
    labels: jobChart.map((job) => job.job),
    datasets: [
      {
        data: jobChart.map((job) => job.percentage),
        backgroundColor: ["rgba(209, 53, 104, 0.8)", "rgba(255, 142, 49, 0.8)"],
        borderColor: ["rgba(209, 53, 104, 1)", "rgba(255, 142, 49, 1)"],
        borderWidth: 1,
      },
    ],
  });

  const getDefaultOptions = () => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { beginAtZero: true, grid: { display: false } },
      y: { beginAtZero: true, max: 100, grid: { display: true } },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  });

  return (
    <div className="w-full h-[300px] md:h-1/2 md:mx-0 mx-4">
      <canvas id="jobChart" style={{ width: "100%", height: "100%" }}></canvas>
    </div>
  );
};

export default ChartHeroJob;
