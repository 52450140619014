"use client";
import React, { useState, useEffect, useCallback } from "react";
import { FaLaugh, FaMeh, FaSadTear, FaSmile } from "react-icons/fa";
import { FaFaceFrown } from "react-icons/fa6";
import { powerCigSimp } from "@/lib/algorithms/power";
import { calcNote } from "@/lib/algorithms/calcNote";
import CIG_SIMP from "@/data/CIG_SIMP.json";
import jobsData from "@/data/metiers.json";
import { saveSessionId, getSessionId, saveResults } from "@/lib/sessionStorage";
import {generatePdf} from "@/lib/pdf";

const icons = [
  { component: FaSadTear, value: 1 },
  { component: FaFaceFrown, value: 2 },
  { component: FaMeh, value: 3 },
  { component: FaSmile, value: 4 },
  { component: FaLaugh, value: 5 },
];

const colors: { [key: number]: string } = {
  1: "#FF602E",
  2: "#FEB448",
  3: "#FFEE5D",
  4: "#83EBAC",
  5: "#29B767",
};

const QuizSection: React.FC = () => {
  const questionsPerPage = 5;
  const totalQuestions = CIG_SIMP.questions.length;
  const totalPages = Math.ceil(totalQuestions / questionsPerPage);

  const [currentPage, setCurrentPage] = useState(0);
  const [progress, setProgress] = useState(0);
  const [resultQuiz, setResultQuiz] = useState<any[]>([]);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [userSignature, setUserSignature] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    const existingSessionId = getSessionId();
    if (existingSessionId) {
      setSessionId(existingSessionId);
      loadQuizResults(existingSessionId);
    } else {
      createQuizSession();
    }
  }, []);

  useEffect(() => {
    if (resultQuiz.length > 0) {
      setProgress(calculateProgress());
    }
  }, [resultQuiz]);

  const loadQuizResults = async (sessionId: string) => {
    try {
      const response = await fetch("/api/refreshQuiz", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          quiz_code: "CIG_SIMP",
        }),
      });
      const data = await response.json();

      if (data && data.answers) {
        const updatedQuiz = CIG_SIMP.questions.map(
          (question: any, index: number) => {
            const answer = data.answers.find(
              (a: any) => a.signature_index === index
            );
            return {
              id: `question-${index}`,
              value: answer ? parseInt(answer.value) : null,
              description: answer
                ? CIG_SIMP.questions[answer.signature_index].options[
                    parseInt(answer.value) - 1
                  ].description
                : null,
            };
          }
        );
        setResultQuiz(updatedQuiz);
      } else {
        setResultQuiz(
          CIG_SIMP.questions.map((_, index) => ({
            id: `question-${index}`,
            value: null,
            description: null,
          }))
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const isNextDisabled = useCallback(() => {
    const start = currentPage * questionsPerPage;
    const end = start + questionsPerPage;
    return resultQuiz.slice(start, end).some((q) => q.value === null);
  }, [currentPage, questionsPerPage, resultQuiz]);

  const createQuizSession = async () => {
    try {
      const response = await fetch("/api/createQuizSession", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ quiz_code: "CIG_SIMP" }),
      });
      const data = await response.json();
      setSessionId(data.id);
      saveSessionId(data.id);

      setResultQuiz(
        CIG_SIMP.questions.map((_, index) => ({
          id: `question-${index}`,
          value: null,
          description: null,
        }))
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const calculateProgress = useCallback(() => {
    const answeredQuestions = resultQuiz.filter((q) => q.value !== null).length;
    return (answeredQuestions / totalQuestions) * 100;
  }, [resultQuiz, totalQuestions]);

  const submitAnswer = async (index: number, value: number) => {
    if (!sessionId) return;

    const currentQuestion = CIG_SIMP.questions[index];
    const answer = {
      quiz_id: "CIG_SIMP",
      skill_pool: currentQuestion.skillPool,
      skill: currentQuestion.skill,
      signature_index: index,
      value: value.toString(),
    };

    try {
      await fetch("/api/submitAnswers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          quiz_code: "CIG_SIMP",
          answers: [answer],
        }),
      });
    } catch (error) {
    }
  };

  const deleteAnswer = async (index: number) => {
    if (!sessionId) return;

    try {
      await fetch("/api/deleteAnswer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          quiz_code: "CIG_SIMP",
          signature_index: index,
        }),
      });
    } catch (error) {
    }
  };

  const handleValueChange = (value: number, index: number) => {
    if (resultQuiz[index].value === value) return;

    if (resultQuiz[index].value !== null) {
      deleteAnswer(index);
    }

    setResultQuiz((prevResults) => {
      const updatedQuiz = [...prevResults];
      updatedQuiz[index].value = value;
      updatedQuiz[index].description =
        CIG_SIMP.questions[index].options[value - 1].description;
      return updatedQuiz;
    });

    submitAnswer(index, value);
    scrollToQuestion(index);
  };

  const calculateResults = () => {
    const userSignature = resultQuiz.map((q) => q.value || 0).join("");
    setUserSignature(userSignature);

    const jobResults = jobsData.map((job) => {
      const metierSignature = job.signatures.find(
        (s) => s.quiz_code === "CIG_SIMP"
      )?.content;
      const metierPower = powerCigSimp(metierSignature || "");
      const percentage = calcNote(
        metierSignature || "",
        metierPower,
        userSignature
      );
      return { job: job.title, percentage };
    });

    saveResults(jobResults);
    storeResults(jobResults);

    window.location.href = "/resultat";
  };

  const storeResults = async (results: any) => {
    try {
      await fetch("/api/storeResults", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: sessionId,
          quiz_code: "CIG_SIMP",
          results,
        }),
      });
    } catch (error) {
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const scrollToQuestion = (index: number) => {
    const questionElement = document.getElementById(`question-${index}`);
    if (questionElement) {
      questionElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const currentQuestions = CIG_SIMP.questions.slice(
    currentPage * questionsPerPage,
    currentPage * questionsPerPage + questionsPerPage
  );

  useEffect(() => {
    // Ne pas scroller lors de la première visite
    if (!firstLoad) {
      const firstQuestion = document.getElementById(
        `question-${currentPage * questionsPerPage}`
      );
      if (firstQuestion) {
        firstQuestion.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } else {
      setFirstLoad(false); // La première visite est passée
    }
  }, [currentPage, questionsPerPage, firstLoad]);

  if (loading) {
    return (
      <div className="w-full flex justify-center items-center h-screen">
        <p className="text-xl font-semibold">
          Chargement du questionnaire en cours...
        </p>
      </div>
    );
  }

  return (
      <section className="w-full flex flex-col justify-center items-center gap-14 py-8 md:py-16">
        <div className="flex flex-row w-2/3 md:w-1/2 justify-center items-center gap-4 rounded-full">
          <p className="font-bold">{Math.trunc(progress)}%</p>
          <div className="w-full h-1 bg-gray-300 rounded-full">
            <div
                className="h-1 transition-all duration-200 rounded-full bg-gradient-to-r from-[#F9C50C] to-[#AB1058]"
                style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>

        {currentQuestions.map((question, index) => {
          const globalIndex = currentPage * questionsPerPage + index;
          return (
              <div
                  key={globalIndex}
                  id={`question-${globalIndex}`}
                  className="flex flex-col justify-center items-center gap-8 px-4"
              >
                <p className="font-semibold text-xl text-center">{question.text}</p>
                <div className="flex flex-row gap-4 justify-center items-center">
                  {icons.map(({ component: Icon, value }) => (
                      <button
                          key={value}
                          onClick={() => handleValueChange(value, globalIndex)}
                          className="hover:opacity-80 transition-opacity duration-200"
                      >
                        <Icon
                            className={`transition-all duration-200 ${
                                resultQuiz[globalIndex]?.value === value
                                    ? "w-14 h-14 md:w-16 md:h-16 lg:w-18 lg:h-18"
                                    : "text-slate-400 w-14 h-14 md:w-16 md:h-16 lg:w-18 lg:h-18"
                            }`}
                            style={{
                              color:
                                  resultQuiz[globalIndex]?.value === value
                                      ? colors[value]
                                      : "gray",
                            }}
                        />
                      </button>
                  ))}
                </div>
                {resultQuiz[globalIndex]?.description && (
                    <p className="text-gray-500 text-center">
                      {resultQuiz[globalIndex]?.description}
                    </p>
                )}
                <div className="w-5/6 md:w-2/3 border-b border-slate-400"></div>
              </div>
          );
        })}

        <div className="flex justify-center w-2/3 mt-6 gap-12">
          <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
              className="bg-[#111213] text-white px-8 py-2 font-semibold rounded-lg disabled:bg-gray-300 transition-all hover:text-[#EBA41B] duration-200"
          >
            Précédent
          </button>
          {currentPage < totalPages - 1 ? (
              <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={isNextDisabled()}
                  className="bg-[#111213] text-white px-8 py-2 font-semibold rounded-lg disabled:bg-gray-300 transition-all hover:text-[#EBA41B] duration-200"
              >
                Suivant
              </button>
          ) : (
              <button
                  onClick={() => calculateResults()}
                  disabled={isNextDisabled()}
                  className="bg-green-500 text-white px-8 py-2 font-semibold rounded-lg disabled:bg-gray-300 transition-all hover:text-[#EBA41B] duration-200"
              >
                Envoyer et calculer les résultats
              </button>
          )}
        </div>
      </section>
  );
};

export default QuizSection;
