/**
 * Calculate a note based on the difference between metierSignature and userSignature,
 * affected by the metierPower.
 *
 * @param {string} metierSignature - The reference signature to compare against.
 * @param {number} metierPower - The power or weight of the metier signature.
 * @param {string} userSignature - The user's signature to be compared.
 * @return {number} The calculated note as a percentage.
 */
export function calcNote(metierSignature, metierPower, userSignature) {
    let cubeMino = 0, cubeMajo = 0;

    for (let i = 0; i < userSignature.length; i++) {
        const difference = metierSignature.charAt(i) - userSignature.charAt(i);

        if (difference < 0) {
            cubeMajo += Math.pow(3, -difference);
        } else if (difference !== 0) {
            cubeMino += Math.pow(3, difference);
        }
    }

    return Math.max((((metierPower - cubeMino + cubeMajo) / metierPower) * ((metierPower - cubeMajo - cubeMino) / metierPower)) * 100, 0);
}
