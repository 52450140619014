import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import { calculateTextPosition } from "../../utils/utils";

export const createSommairePage = async (pdfDoc, data, backgroundImageUrl, logoImageUrl, fonts) => {
    const page = await createPageTemplate(pdfDoc, data, backgroundImageUrl);

    const title = "Sommaire";
    const startYTitle = layout.pageHeight - layout.margin - 100;

    const { x: titleX, y: titleY } = calculateTextPosition(
        title, fonts.topTitleFont, styles.titleStyle.size, startYTitle, "center"
    );

    page.drawText(title, {
        x: titleX,
        y: titleY,
        size: styles.titleStyle.size * 1.33,
        font: fonts.titleFont,
        color: styles.titleStyle.color,
        lineHeight: styles.titleStyle.size * 1.2,
    });

    const metiersText = data.results.length === 1
        ? "• Vos résultats pour le métier choisi"
        : `• Vos résultats pour les ${data.results.length} métiers choisis`;

    const text = [
        metiersText,
        "",
        "• Votre détail de résultats (par métier) :",
        "  - Groupes de compétences forts",
        "  - Forces & Faiblesses de votre profil",
        "  - Synthèse profil & axes d’amélioration",
        "  - Fiche Métier",
        "",
        "• Synthèse des résultats pour 50 métiers :",
        "  - Correspondance par type de métier",
        "  - Correspondance par métier"
    ].join("\n");

    const { x, y } = calculateTextPosition(
        text, fonts.bodyFont, styles.bodyStyle.size * 1.5, startYTitle - 300, "left"
    );

    page.drawText(text, {
        x: x,
        y: y + 150,
        size: styles.bodyStyle.size * 1.5,
        font: fonts.bodyFont,
        color: styles.bodyStyle.color,
        lineHeight: styles.bodyStyle.size * 1.66,
    });

    return page;
};
