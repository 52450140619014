"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";

const SkillsSection = () => {
  const [topSkills, setTopSkills] = useState<any[]>([]);

  useEffect(() => {
    const fetchTopSkills = async () => {
      const sessionId = localStorage.getItem("quiz_session_id");
      if (!sessionId) return;

      try {
        const response = await fetch("/api/getAnswersResults", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: sessionId,
            quiz_code: "CIG_SIMP",
          }),
        });

        const data = await response.json();
        const sortedSkills = data.answers
          .sort((a: any, b: any) => b.value - a.value)
          .slice(0, 2);
        setTopSkills(sortedSkills);
      } catch (error) {
      }
    };

    fetchTopSkills();
  }, []);

  return (
    <section className="flex flex-col justify-center gap-4 mt-4 md:mt-8">
      <p className="max-w-[600px] text-gray-500 text-base">
        Grâce à tes compétences en{" "}
        <span className="font-bold">
          {topSkills[0]?.skill || "[compétence clé]"}
        </span>{" "}
        et ta capacité en{" "}
        <span className="font-bold">
          {topSkills[1]?.skill || "[compétence ou trait personnel]"}
        </span>
        , tu es fait(e) pour exceller dans ce domaine. Prêt(e) à franchir cette
        étape ? 🚀
      </p>
      <p className="max-w-[600px] text-lg font-bold mt-8">
        Ton parcours est unique, et ce métier reflète parfaitement tes points
        forts. Tu es maintenant à un pas d'une carrière qui te correspond
        vraiment.
      </p>
      <p className="max-w-[600px] text-gray-500 text-base mt-4 font-emphasis">
        Psst, tu peux scroller vers le bas pour découvrir ton classement complet
        et télécharger ton rapport personnalisé !
      </p>
      {/* J'ai désactivé le bouton ici car pour scroll dans les résultats ça impliquerait un useRef dans resultPage, sauf qu'on veut la garder en SSR :// */}
      {/*
            <div className="overflow-hidden rounded-lg shadow max-w-[16rem]">
                <Link
                    href="/quiz"
                    className="flex items-center justify-center px-8 py-2 text-base font-medium text-white transition-colors duration-150 bg-[#111213]"
                >
                    Obtenir le rapport
                </Link>
            </div>
            */}
    </section>
  );
};

export default SkillsSection;
