import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { styles } from "../../config/styles";
import {calculateTextPosition, formatText, getBottomYPosition} from "../../utils/utils";

export const createJobInfos2Template = async (pdfDoc, data, backgroundImageUrl, fonts, jobIndex = 0) => {
    const jobTitle = formatText(`${data.results[jobIndex].metier.toUpperCase()} - FICHE MÉTIER`, 30);
    const page = await createPageTemplate(pdfDoc, data, backgroundImageUrl, true, jobTitle);

    const jobInfo = data.jobInfos.find(job => job.metierNom === data.results[jobIndex].metier);

    const titleYPosition = layout.pageHeight - layout.margin - 160;
    drawTextElement(page, "TENDANCE DU MARCHÉ", fonts.titleFont, styles.titleStyle, layout.margin, titleYPosition);

    const textTrends = formatText(jobInfo.trends);
    const textTrendsYPosition = getBottomYPosition("TENDANCE DU MARCHÉ", fonts.titleFont, styles.titleStyle.size, titleYPosition, styles.titleStyle.size * 1.5);
    drawTextElement(page, textTrends, fonts.bodyFont, styles.bodyStyle, layout.margin, textTrendsYPosition - 20);

    const careerPathTitleYPosition = getBottomYPosition(textTrends, fonts.bodyFont, styles.bodyStyle.size, textTrendsYPosition, styles.bodyStyle.size * 1.5) - 80;
    drawTextElement(page, "CHEMIN DE CARRIÈRE", fonts.titleFont, styles.titleStyle, layout.margin, careerPathTitleYPosition);

    const careerPathText = formatText(jobInfo.careerPath);
    drawTextElement(page, careerPathText, fonts.bodyFont, styles.bodyStyle, layout.margin, careerPathTitleYPosition - 80);

    return page;
};

// TODO : move in utils
const drawTextElement = (page, text, font, style, x, y) => {
    const { x: adjustedX, y: adjustedY } = calculateTextPosition(text, font, style.size, y, "left");
    page.drawText(text, {
        x: adjustedX,
        y: adjustedY,
        size: style.size,
        font: font,
        color: style.color || rgb(35 / 255, 40 / 255, 58 / 255),
        lineHeight: style.size * 1.5,
    });
};