import { createPageTemplate } from "../pageTemplate";
import { layout } from "../../config/layout";
import { formatText } from "../../utils/utils";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const createJobSynthesisTemplatesV2 = async (pdfDoc, data, backgroundImageUrl, fonts) => {
    const sortedJobs = [...data].sort((a, b) => b.percentage - a.percentage);

    const maxJobsPerPage = 25;
    for (let i = 0; i < Math.min(sortedJobs.length, 50); i += maxJobsPerPage) {
        const jobsForCurrentPage = sortedJobs.slice(i, i + maxJobsPerPage);
        await createJobSynthesisTemplateV2(pdfDoc, jobsForCurrentPage, backgroundImageUrl, fonts, `Métiers ${i + 1} à ${i + jobsForCurrentPage.length}`);
    }
};

const createJobSynthesisTemplateV2 = async (pdfDoc, jobs, backgroundImageUrl, fonts, title) => {
    const page = await createPageTemplate(pdfDoc, {}, backgroundImageUrl, true, formatText(`SYNTHÈSE DES MÉTIERS - ${title.toUpperCase()}`, 30));

    const barChartImageUrl = await generateBarChartImage(jobs);

    const barChartImageBytes = await fetch(barChartImageUrl).then(res => res.arrayBuffer());
    const barChartImageEmbed = await pdfDoc.embedPng(barChartImageBytes);
    const chartDims = barChartImageEmbed.scale(0.5);

    page.drawImage(barChartImageEmbed, {
        x: layout.pageWidth / 2 - chartDims.width / 2,
        y: layout.pageHeight / 2 - chartDims.height / 2 - 80,
        width: chartDims.width,
        height: chartDims.height,
    });

    return page;
};

const generateBarChartImage = async (jobs) => {
    return new Promise((resolve) => {
        const container = document.createElement('div');
        container.style.width = '800px';
        container.style.height = '900px';
        container.style.padding = '20px';
        document.body.appendChild(container);

        const canvas = document.createElement('canvas');
        container.appendChild(canvas);

        const ctx = canvas.getContext('2d');

        Chart.register(ChartDataLabels);

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: jobs.map(job => job.job),
                datasets: [{
                    label: 'Niveau de compatibilité',
                    data: jobs.map(job => job.percentage),
                    backgroundColor: 'rgba(255, 142, 49, 1)',
                    borderColor: 'rgba(255, 142, 49, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        min: 0,
                        max: 100,
                        ticks: {
                            stepSize: 20,
                            font: {
                                size: 16
                            }
                        }
                    },
                    y: {
                        ticks: {
                            display: false,
                            autoSkip: false,
                            font: {
                                size: 14
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    datalabels: {
                        anchor: 'center',
                        align: 'center',
                        color: '#fff',
                        font: {
                            size: 18,
                            weight: 'bold'
                        },
                        formatter: (value, context) => {
                            return `${context.chart.data.labels[context.dataIndex]} (${value.toFixed(2)}%)`;                        }
                    }
                },
                categoryPercentage: 0.8,
                barPercentage: 0.8
            }
        });

        setTimeout(() => {
            const imageUrl = canvas.toDataURL('image/png');
            document.body.removeChild(container); // Supprimer le conteneur
            resolve(imageUrl);
        }, 1000);
    });
};
